// 料金データを直接JavaScriptオブジェクトに定義
const priceTable = [
  { facility: "全館", day: "平日", admission: "無料", "9-12時": 43640, "13-17時": 58220, "18-21時": 52440, "9-17時": 101870, "13-21時": 110670, "9-21時": 154300 },
  { facility: "全館", day: "平日", admission: "有料", "9-12時": 87290, "13-17時": 116490, "18-21時": 104790, "9-17時": 203790, "13-21時": 221290, "9-21時": 308570 },
  { facility: "全館", day: "土日祝", admission: "無料", "9-12時": 54530, "13-17時": 72820, "18-21時": 65410, "9-17時": 127350, "13-21時": 138240, "9-21時": 192760 },
  { facility: "全館", day: "土日祝", admission: "有料", "9-12時": 109180, "13-17時": 145560, "18-21時": 130960, "9-17時": 254750, "13-21時": 276520, "9-21時": 385700 },
  { facility: "舞台", day: "平日", admission: "無料", "9-12時": 11340, "13-17時": 15160, "18-21時": 13650, "9-17時": 26510, "13-21時": 28820, "9-21時": 40150 },
  { facility: "舞台", day: "平日", admission: "有料", "9-12時": 22690, "13-17時": 30340, "18-21時": 27210, "9-17時": 53030, "13-21時": 57550, "9-21時": 80240 },
  { facility: "舞台", day: "土日祝", admission: "無料", "9-12時": 14120, "13-17時": 18980, "18-21時": 16900, "9-17時": 33110, "13-21時": 35890, "9-21時": 50000 },
  { facility: "舞台", day: "土日祝", admission: "有料", "9-12時": 28360, "13-17時": 37860, "18-21時": 34030, "9-17時": 66230, "13-21時": 71900, "9-21時": 100250 },
  { facility: "楽屋", day: "平日", admission: "無料", "9-12時": 6130, "13-17時": 8090, "18-21時": 7400, "9-17時": 14220, "13-21時": 15500, "9-21時": 21620 },
  { facility: "楽屋", day: "平日", admission: "有料", "9-12時": 12150, "13-17時": 16320, "18-21時": 14580, "9-17時": 28470, "13-21時": 30910, "9-21時": 43050 },
  { facility: "楽屋", day: "土日祝", admission: "無料", "9-12時": 7630, "13-17時": 10180, "18-21時": 9140, "9-17時": 17820, "13-21時": 19330, "9-21時": 26950 },
  { facility: "楽屋", day: "土日祝", admission: "有料", "9-12時": 15270, "13-17時": 20380, "18-21時": 18290, "9-17時": 35650, "13-21時": 38670, "9-21時": 53940 },
  { facility: "見所", day: "平日", admission: "無料", "9-12時": 26160, "13-17時": 34960, "18-21時": 31380, "9-17時": 61130, "13-21時": 66340, "9-21時": 92500 },
  { facility: "見所", day: "平日", admission: "有料", "9-12時": 52450, "13-17時": 69820, "18-21時": 62990, "9-17時": 122280, "13-21時": 132820, "9-21時": 185260 },
  { facility: "見所", day: "土日祝", admission: "無料", "9-12時": 32760, "13-17時": 43650, "18-21時": 39360, "9-17時": 76410, "13-21時": 83010, "9-21時": 115770 },
  { facility: "見所", day: "土日祝", admission: "有料", "9-12時": 65540, "13-17時": 87310, "18-21時": 78620, "9-17時": 152850, "13-21時": 165940, "9-21時": 231470 }
];

// ----------------------------
// イベントリスナー（料金計算）
// ----------------------------
document.addEventListener("DOMContentLoaded", () => {
  // 必要な要素の取得
  const calculateBtn = document.getElementById("calculate");
  const resultElement = document.getElementById("total-amount");
  const facilityInputs = document.querySelectorAll('input[name="facility"]');

  // calculateボタンもしくは結果表示要素が存在しないページの場合はスキップ
  if (!calculateBtn || !resultElement) {
    return;
  }

  // 施設選択の相互排他制御の設定
  facilityInputs.forEach(input => {
    input.addEventListener('change', function(e) {
      if (e.target.value === "全館" && e.target.checked) {
        // 全館が選択された場合、他の施設のチェックを外す
        facilityInputs.forEach(inp => {
          if (inp.value !== "全館") {
            inp.checked = false;
            updateLabelClass(inp);
          }
        });
      } else if (e.target.value !== "全館" && e.target.checked) {
        // 他の施設が選択された場合、全館のチェックを外す
        const zenkanInput = Array.from(facilityInputs).find(inp => inp.value === "全館");
        if (zenkanInput) {
          zenkanInput.checked = false;
          updateLabelClass(zenkanInput);
        }
      }
      updateLabelClass(e.target);
    });
  });

  // 料金計算処理
  calculateBtn.addEventListener("click", () => {
    const dayType = document.querySelector('input[name="day-type"]:checked')?.value?.trim();
    const timeSlot = document.querySelector('input[name="time-slot"]:checked')?.value?.trim();
    const admission = document.querySelector('input[name="admission"]:checked')?.value?.trim();
    
    // 選択された施設を配列として取得
    const selectedFacilities = Array.from(facilityInputs)
      .filter(inp => inp.checked)
      .map(inp => inp.value);

    // 入力チェック
    if (!dayType || selectedFacilities.length === 0 || !timeSlot || !admission) {
      resultElement.textContent = "利用条件が正しく選択されていません";
      return;
    }

    // 合計金額の計算
    let totalAmount = 0;
    selectedFacilities.forEach(facility => {
      const selectedOption = priceTable.find(
        row =>
          row.facility === facility &&
          row.day === dayType &&
          row.admission === admission
      );

      if (selectedOption) {
        totalAmount += selectedOption[timeSlot] || 0;
      }
    });

    // 日本円表示
    resultElement.textContent = totalAmount.toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol'
    });
  });
});

// ラジオボタンとチェックボックスのUIイベントリスナー
document.addEventListener('DOMContentLoaded', function() {
  // 全ての入力要素を取得
  const allInputs = document.querySelectorAll('.simulation-form-input-radio');

  // 入力要素が一つもなければスキップ
  if (!allInputs.length) {
    return;
  }

  // 入力要素のイベントリスナー
  allInputs.forEach(function(input) {
    input.addEventListener('change', function() {
      updateLabelClass(input);
    });
  });

  // 初期状態の設定
  const checkedInputs = document.querySelectorAll('.simulation-form-input-radio:checked');
  checkedInputs.forEach(function(input) {
    updateLabelClass(input);
  });
});

// ラベルのクラス更新用ヘルパー関数
function updateLabelClass(input) {
  const group = input.closest('.simulation-form-input-group');
  if (!group) return;

  // type="checkbox" の場合は個別に処理
  if (input.type === 'checkbox') {
    const label = input.closest('.simulation-form-input-label');
    if (label) {
      if (input.checked) {
        label.classList.add('js-selected');
      } else {
        label.classList.remove('js-selected');
      }
    }
    return;
  }

  // type="radio" の場合は従来通りの処理
  group.querySelectorAll('.simulation-form-input-label').forEach(function(label) {
    label.classList.remove('js-selected');
  });

  if (input.checked) {
    input.closest('.simulation-form-input-label').classList.add('js-selected');
  }
}