import Accordion from '../../plugins/accordion.js';
const elements = document.querySelectorAll(".js-accordion");
for (const element of elements) {
  let accordion = new Accordion(element, {
    multipleOpen: true,
    onOpen: (trigger, panel) => {},
    onClose: (trigger, panel) => {}
  });
}

// accordion.destroy()
// accordion.init()
