const smoothScroll = (event) => {
  event.preventDefault();
  
  // ヘッダー高さ + 追加オフセット
  const totalOffset = '6.5rem'; // 4rem + 2.5rem
  const href = event.currentTarget.getAttribute('href');
  const target = document.querySelector(href);
  
  // remをピクセルに変換（1rem = 16px）
  const offsetPx = parseFloat(totalOffset) * 16;
  
  const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - offsetPx;
  
  window.scrollTo({
    top: targetPosition,
    behavior: 'smooth'
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  anchorLinks.forEach(link => {
    link.addEventListener('click', smoothScroll);
  });
});