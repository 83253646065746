import Simplemodal from '../../plugins/simplemodal.js';

const MATTERPORT_URL = 'https://my.matterport.com/show/?m=SupSfVkzUyC';
let activeModals = new Set();

// 元のiframe情報を保存するためのMap
const originalIframes = new Map();

const simplemodal = new Simplemodal({
  onOpen: (trigger, content) => {
    // 既に開いているモーダルがあれば閉じる
    if (activeModals.size > 0) {
      activeModals.forEach(modalId => {
        const modal = document.getElementById(modalId);
        if (modal) {
          const modalTrigger = document.querySelector(`[data-simplemodal-trigger="${modalId}"]`);
          simplemodal.modalClose(modalTrigger, modal);
        }
      });
      activeModals.clear();
    }

    const modalContent = content.querySelector('.c-modal__content');
    let existingIframe = modalContent?.querySelector('iframe');
    
    // iframeが存在しない場合、保存された情報から復元
    if (!existingIframe && originalIframes.has(content.id)) {
      const iframeInfo = originalIframes.get(content.id);
      existingIframe = document.createElement('iframe');
      Object.assign(existingIframe, iframeInfo);
    }
    
    // 初回表示時の場合
    if (!originalIframes.has(content.id) && existingIframe) {
      // 元のiframe情報を保存
      originalIframes.set(content.id, {
        width: existingIframe.width,
        height: existingIframe.height,
        title: existingIframe.title,
        dataset: { matterportParams: existingIframe.dataset.matterportParams },
        frameBorder: existingIframe.getAttribute('frameborder'),
        allowFullscreen: existingIframe.allowFullscreen
      });
    }

    if (!existingIframe) {
      console.error('iframeが見つかりません');
      return;
    }

    // iframeのdata属性を保持
    const params = existingIframe.dataset?.matterportParams || '';
    const title = existingIframe.title || '';
    
    // 既存のiframeを削除
    if (existingIframe.parentNode) {
      existingIframe.remove();
    }
    
    // 新しいiframeを作成
    const newIframe = document.createElement('iframe');
    newIframe.width = "1024";
    newIframe.height = "576";
    newIframe.title = title;
    newIframe.frameBorder = "0";
    newIframe.allowFullscreen = true;
    newIframe.src = params ? `${MATTERPORT_URL}&${params}` : MATTERPORT_URL;
    
    // closeボタンの前に新しいiframeを挿入
    const closeButton = modalContent.querySelector('.c-modal-button__close');
    if (closeButton) {
      modalContent.insertBefore(newIframe, closeButton);
    } else {
      modalContent.appendChild(newIframe);
    }

    // アクティブなモーダルを記録
    const modalId = content.id;
    activeModals.add(modalId);
  },
  onClose: (trigger, content) => {
    const modalId = content.id;
    activeModals.delete(modalId);
    
    const modalContent = content?.querySelector('.c-modal__content');
    const iframe = modalContent?.querySelector('iframe');
    
    // iframeのsrcだけをクリア（要素自体は削除しない）
    if (iframe) {
      iframe.src = '';
    }
  }
});