document.addEventListener('DOMContentLoaded', () => {
  const topAlert = document.querySelector('.top-alert');
  
  // アラートが存在する場合のみ処理を実行
  if (topAlert) {
    const closeButton = topAlert.querySelector('.js-close');
    const body = document.body;
    
    // セッションストレージをチェック
    if (sessionStorage.getItem('alertClosed')) {
      topAlert.style.display = 'none';
    } else {
      // アラートが表示される場合のみbackfixを適用
      body.classList.add('backfix');
    }

    // closeButtonが存在する場合のみイベントリスナーを追加
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        // backfixを解除
        body.classList.remove('backfix');
        
        // アラートを非表示
        topAlert.style.display = 'none';

        // セッションストレージに状態を保存
        sessionStorage.setItem('alertClosed', true);
        // console.log(sessionStorage.getItem('alertClosed'));
      });
    }
  }
});